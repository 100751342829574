<template>
  <v-sheet class="wsRoundedHalf pa-6 mt-6">
    <h3 class="font-weight-medium">{{  $t('Courses')  }}</h3>
    <v-fade-transition mode="out-in">

      <v-data-table
          v-if="items.length > 0"
          class="mt-6"
          :items="items"
          :headers="headers"
      >

        <template v-slot:item="{item}">
          <tr>
            <td style="min-width: 150px">
              <h5 class=font-weight-medium>{{ item.course_name }}</h5>
            </td>
            <td>
              <ws-chip :text="item.enrollment_wave_name ? item.enrollment_wave_name : $t('NoSchedule')"  icon="mdi-calendar" ></ws-chip>
            </td>
            <td>
              <ws-chip :text="item.payment_plan_name ? item.payment_plan_name : $t('PaymentPlan')"  icon="mdi-calendar" >
              </ws-chip>
            </td>
            <td>
              <v-chip  class="my-1 mr-2 pointer" small outlined :color="item.active ? 'green lighten-1' : 'grey'"  >
                <h4 class="font-weight-medium">{{ item.active ? $t('Active') : $t('NotActive') }}</h4>
              </v-chip>
            </td>

            <td>
              <v-chip  class="my-1 mr-2 pointer" small outlined :color="item.is_purchased ? 'green lighten-1' : 'grey'"  >
                <h4 class="font-weight-medium">{{ item.is_purchased ? $t('PaymentConfirmed') : $t('PaymentNotConfirmed') }}</h4>
              </v-chip>
            </td>
            <td>
              <ws-chip class="text-no-wrap" :text="item.date"  icon="mdi-calendar" />
            </td>
            <td width="10" align="center">
              <v-progress-circular color="green lighten-2" :value="item.completion" >
                <h6 class="font-weight-light grey--text">{{ item.completion }}</h6>
              </v-progress-circular>
            </td>

          </tr>

        </template>

      </v-data-table>
      <v-sheet v-else :color="wsACCENT" class="wsRoundedHalf pa-6 py-16 mt-6" dark>
        <h5 class="font-weight-light text-center"> {{ $t('ClientHasNoCoursesYet') }}</h5>
      </v-sheet>

    </v-fade-transition>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "clientCourses",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      items : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Course_')         , value : 'course_name' },
        { text : this.$t('EnrollmentWave') , value : 'enrollment_wave_name'  , align : 'center' , sortable : false   },
        { text : this.$t('PaymentPlan')    , value : 'payment_plan_name'     , align : 'center' , sortable : false    },
        { text : this.$t('Active')         , value : 'active'                , align : 'center'     },
        { text : this.$t('Payment')        , value : 'is_purchased'          , align : 'center'     },
        { text : this.$t('EnrollmentDate') , value : 'date'      },
        { text : this.$t('Completion') , value : 'completion'    },
      ]
    }
  },
  methods : {
    ...mapActions( 'crmSystem', [
      'GET_CLIENT_COURSES' , ]),

    async initPage() {
      let result = await this.GET_CLIENT_COURSES(this.uuid)
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>